import type { Nav } from '~/types/Nav'

// interface TransformedLink {
//   order?: number
//   label: string
//   icon: string
//   to: string
// }

export const useNavLinks = () => {
  const { localizeString } = useLocalization()
  const { locale } = useI18n()
  const localePath = useLocalePath()

  const { data: navLinks, error } = useFetch('/api/nav', {
    key: `navigation-${locale.value}`,
    transform: (links: Nav[]) =>
      links
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .map((link) => ({
          label: localizeString(link.label, link.hkLabel),
          description: localizeString(link.description, link.hkDescription),
          icon: link.icon,
          to: localePath(link.to),
          target: link.target || null,
          cover: link.cover.formats?.large?.url,
          alt: link.cover.alternativeText,
          children:
            link.children?.map((child) => ({
              id: child.id,
              label: localizeString(child.label, child.hkLabel),
              title: localizeString(child.label, child.hkLabel),
              description: localizeString(
                child.description,
                child.hkDescription
              ),
              icon: child.icon,
              to: localePath(child.to),
              target: child.target || null,
              cover: child.cover.formats?.large?.url,
              alt: child.cover.alternativeText,
              isLandingVisible: child.isLandingVisible,
            })) || [],
        })),
    watch: [locale],
  })

  return {
    navLinks,
    error,
  }
}

// import type { Nav } from '~/types/Nav'

// export function useNavLinks() {
//   const { locale } = useI18n()
//   const localePath = useLocalePath()

//   const { data: rawNavLinks } = useFetch<Nav[]>('/api/nav')

//   const isHK = computed(() => locale.value === 'hk')

//   const navLinks = computed(() => {
//     if (!rawNavLinks.value || !Array.isArray(rawNavLinks.value)) {
//       return []
//     }

//     return rawNavLinks.value.map((item) => ({
//       // ...item,

//       label: isHK.value ? item.hkLabel : item.label,
//       description: isHK.value ? item.hkDescription : item.description,
//       icon: item.icon || null,
//       to: localePath(item.to),
//       target: item.target || null,
//       children:
//         item.children?.map((child) => ({
//           // ...child,
//           id: child.id,
//           // order: child.order,
//           label: isHK.value ? child.hkLabel : child.label,
//           title: isHK.value ? child.hkLabel : child.label,
//           description: isHK.value ? child.hkDescription : child.description,
//           icon: child.icon,
//           to: localePath(child.to),
//           target: child.target,
//           cover: child.cover,
//           isLandingVisible: child.isLandingVisible,
//         })) || [],
//       cover: item.cover,
//     }))
//   })

//   return {
//     navLinks,
//   }
// }
