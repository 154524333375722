<script setup lang="ts">
import AnimatedGradientText from '~/components/Spark/AnimatedGradientText.vue'
import { cn } from '~/lib/utils'

const { t } = useI18n()
const localePath = useLocalePath()

const { navLinks } = useNavLinks()
const safeNavLinks = computed(() => navLinks.value ?? [])

const { socialLinks } = useAppConfig()

const { clinics } = useClinic()

const currentYear = computed(() => new Date().getFullYear())

const legalLinks = computed(() => [
  { name: t('footer.termsOfUse'), to: localePath('/terms-of-use') },
  { name: t('footer.privacyPolicy'), to: localePath('/privacy-policy') },
])

const footerUi = {
  wrapper: 'bg-neutral-50 dark:bg-neutral-800/50',
  top: {
    wrapper: 'border-b border-neutral-200 dark:border-neutral-700',
  },
}

const columnsUi = {
  wrapper: 'md:grid md:grid-cols-2 xl:grid-cols-2 md:gap-8',
  active: 'text-primary-500 dark:text-primary-500',
  // center:
  //   'flex flex-col lg:grid grid-flow-col auto-cols-fr gap-4 xl:col-span-2',
  // label: 'text-lg font-semibold text-slate-900 dark:text-white',
  // base: 'text-base text-zinc-500 dark:text-zinc-500 relative hover:transition-transform duration-700 ease-in-out',
}
</script>

<template>
  <UFooter :ui="footerUi">
    <template #top>
      <div class="flex flex-row justify-center gap-4 md:gap-6 lg:gap-8 pb-12">
        <NuxtLink
          v-for="clinic in clinics"
          :key="clinic.fullLogoUrl"
          :to="localePath('/')"
        >
          <span class="sr-only">{{ clinic.label }}</span>
          <img
            :src="clinic.fullLogoUrl"
            class="h-12 w-36 lg:h-12 lg:w-48"
            :alt="clinic.label + ' Logo'"
          />
        </NuxtLink>
      </div>

      <!-- Desktop  -->
      <KFooterColumns
        :ui="columnsUi"
        :links="safeNavLinks"
        class="hidden md:block"
      />

      <!-- Mobile -->
      <KFooterLinks
        :ui="{
          wrapper: 'grid grid-cols-2 gap-4 text-center',
          active: 'text-primary-500',
        }"
        :links="safeNavLinks"
        class="md:hidden"
      />

      <!-- <div class="grid place-items-center w-full">
        <AnimatedGradientText>
          <span
            :class="
              cn(
                `inline animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#ff0000] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`
              )
            "
          >
            123
          </span>
        </AnimatedGradientText>
      </div> -->
    </template>

    <template #left>
      <div class="flex flex-col space-y-2 py-4 lg:py-8">
        <span class="text-xs">
          © {{ currentYear }} Shoebill Health Care All Rights Reserved.
        </span>
        <div class="flex space-x-2 text-xs justify-center lg:justify-start">
          <NuxtLink
            v-for="link in legalLinks"
            :key="link.to"
            :to="link.to"
            class="hover:text-neutral-950 dark:hover:text-white transition-colors duration-500"
          >
            {{ link.name }}
          </NuxtLink>
        </div>
      </div>
    </template>

    <template #right>
      <template v-for="link in socialLinks" :key="link.url">
        <UPopover v-if="link.label === 'WeChat'" mode="click">
          <UButton
            :icon="link.icon"
            color="gray"
            variant="ghost"
            target="_blank"
            class="hover:text-primary-500 transition-colors duration-500"
          />
          <template #panel>
            <div class="p-0">
              <NuxtImg
                src="https://shoebill.sgp1.cdn.digitaloceanspaces.com/img/wechat.webp"
                alt="Shoebill Health Care Wechat QR Code"
                class="w-[317px] h-[433px]"
              />
            </div>
          </template>
        </UPopover>

        <UButton
          v-else
          :icon="link.icon"
          color="gray"
          variant="ghost"
          :to="link.url"
          target="_blank"
          class="hover:text-primary-400 transition-colors duration-500"
        />
      </template>
    </template>
  </UFooter>
</template>
