export const useLocalization = () => {
  const { locale } = useI18n()

  const isHK = computed(() => locale.value === 'hk')

  const localizeString = (enString: string, hkString: string) => {
    return isHK.value ? hkString : enString
  }

  return {
    isHK,
    localizeString,
  }
}
