import { type ClassValue, clsx } from 'clsx'
import type { Clinic } from '~/types/Clinic'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export type ObjectValues<T> = T[keyof T]

export const getSlug = (clinic: Clinic) => {
  return clinic.label
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/--+/g, '-') // Replace multiple hyphens with single hyphen
    .trim() // Remove leading and trailing spaces/hyphens
}

export const getRadius = (index: number) => {
  // const radius = [100, 150, 200]
  // return radius[index % 3]
  return index < 4 ? 120 : 180
}

export const getDuration = (index: number) => {
  const minDuration = 10
  const maxDuration = 20
  const randomFactor = Math.random()
  return Math.min(
    maxDuration,
    Math.max(minDuration, minDuration + randomFactor * index * 2)
  )
}

export const getDelay = (index: number) => {
  // First 4 items: 5, 10, 15, 20
  if (index < 4) {
    return (index + 1) * 5
  }
  // Subsequent items: 4, 8, 12, 16, 20
  return Math.min(20, (index - 3) * 4)
}

export const isReverse = (index: number) => {
  return (index - 1) % 3 === 0
}
