<script setup lang="ts">
const { locale } = useI18n()
const localePath = useLocalePath()

const { announcement, status, error } = useAnnouncement()
const isActive = computed(() => {
  return announcement.value && announcement.value?.isActive && !error.value
})

useHead(() => ({
  htmlAttrs: {
    lang: locale.value === 'hk' ? 'zh-HK' : 'en',
  },
  meta: [
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/img/favicon.png',
    },
  ],
}))
</script>

<template>
  <!-- Announcement Bar -->
  <div v-if="isActive || status === 'pending'" class="top-0 left-0 w-full z-50">
    <div
      class="flex items-center justify-center bg-primary-500 px-6 py-2.5 sm:px-3.5 group transition-all duration-500 hover:bg-primary-600"
    >
      <div class="flex items-center justify-center w-full">
        <UIcon
          name="i-tabler-speakerphone"
          class="h-5 w-5 text-white mr-2 transition-transform duration-500 group-hover:scale-110 flex-shrink-0"
        />

        <!-- Loading Skeleton -->
        <div v-if="status === 'pending'" class="animate-pulse">
          <div class="h-4 w-48 bg-white/20 rounded" />
        </div>

        <!-- Error Message -->
        <p v-else-if="error" class="text-sm text-white text-center">
          <UIcon
            name="i-tabler-alert-circle"
            class="h-5 w-5 text-white mr-2 inline-block"
          />
          {{ error.message }}
        </p>

        <!-- Announcement Content -->
        <p v-else class="text-sm text-white text-center">
          <ULink
            :to="localePath('/announcement')"
            class="group-hover:text-neutral-100 font-bold transition-all duration-500"
          >
            {{ announcement?.title }}
          </ULink>
        </p>
      </div>
    </div>
  </div>
  <AppHeader />

  <UMain>
    <NuxtLoadingIndicator
      :height="5"
      :duration="3000"
      :throttle="400"
      color="orange"
    />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <!-- <UNotifications /> -->
  </UMain>

  <AppFooter />
</template>
