<script setup lang="ts">
const { t } = useI18n()
const error = useError()
const localePath = useLocalePath()

const errorCode = computed(() => error.value?.statusCode || 404)
const errorMessage = computed(
  () => error.value?.statusMessage || 'Page not found'
)

// Use SEO meta tags
useSeoMeta({
  title: `${errorCode.value} - ${errorMessage.value}`,
  description: t('error.description'),
})
</script>

<template>
  <UContainer class="min-h-screen flex items-center justify-center p-4">
    <div class="text-center">
      <h1 class="text-6xl md:text-9xl font-bold text-primary-500 animate-pulse">
        {{ errorCode }}
      </h1>
      <h2
        class="mt-4 text-2xl md:text-3xl font-semibold text-gray-700 dark:text-gray-300"
      >
        {{ errorMessage }}
      </h2>
      <p class="mt-4 text-base md:text-lg text-gray-600 dark:text-gray-400">
        {{ t('error.description') }}
      </p>
      <div class="mt-8">
        <UButton
          icon="i-heroicons-arrow-left-20-solid"
          :label="t('cta.goBackHome')"
          :to="localePath('/')"
          size="lg"
          color="primary"
        />
      </div>
    </div>
  </UContainer>
</template>
