<script setup lang="ts">
import type { PropType } from 'vue'
import type { DeepPartial } from '#ui/types'
import type { NavigationGroup, NavigationTree } from '#ui-pro/types'

const config = {
  wrapper: 'space-y-3',
  accordion: {},
  links: {},
}

const localePath = useLocalePath()

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  links: {
    type: Array as PropType<NavigationTree[]>,
    default: () => [],
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  defaultOpen: {
    type: [Boolean, Number],
    default: undefined,
  },
  class: {
    type: [String, Object, Array] as PropType<any>,
    default: undefined,
  },
  ui: {
    type: Object as PropType<DeepPartial<typeof config>>,
    default: () => ({}),
  },
})

const { ui, attrs } = useUI(
  'navigation.tree',
  toRef(props, 'ui'),
  config,
  toRef(props, 'class'),
  true
)

const groups = computed<NavigationGroup[]>(() => {
  const groups: NavigationGroup[] = []

  let group: NavigationGroup = { type: undefined, children: [] }

  for (const link of props.links) {
    const type = link.children?.length ? 'accordion' : 'link'
    if (!group.type) {
      group.type = type
    }

    if (group.type === type) {
      group.children.push(link)
    } else {
      groups.push(group)
      group = { type, children: [link] }
    }
  }

  if (group.children.length) {
    groups.push(group)
  }

  return groups
})
</script>

<template>
  <nav v-if="groups?.length" :class="ui.wrapper" v-bind="attrs">
    <template v-for="(group, index) in groups" :key="index">
      <UNavigationAccordion
        v-if="group.type === 'accordion'"
        :links="group.children"
        :level="level"
        :multiple="multiple"
        :default-open="defaultOpen"
        :ui="{ ...ui.accordion, links: ui.links }"
      />

      <!-- add booking cta -->
      <div v-else class="z-10 flex items-center justify-start">
        <ULink :to="localePath('/appointment')">
          <InspiraShimmerButton
            class="shadow-2xl"
            shimmerSize="2px"
            background="rgba(249,115,22,1)"
          >
            <span
              class="whitespace-pre-wrap text-left text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10"
            >
              {{ group.children[0].label }}
            </span>
          </InspiraShimmerButton>
        </ULink>
      </div>

      <!-- <UNavigationLinks
        v-else
        :links="group.children"
        :level="level"
        :ui="ui.links"
      /> -->
    </template>
  </nav>
</template>
