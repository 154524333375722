import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as announcementHNfDlFc16vMeta } from "/vercel/path0/pages/announcement.vue?macro=true";
import { default as appointmentg216JUSXNDMeta } from "/vercel/path0/pages/appointment.vue?macro=true";
import { default as _91_46_46_46slug_93UMOvHPRzc7Meta } from "/vercel/path0/pages/articles/[...slug].vue?macro=true";
import { default as _91slug_93XsmqOjq6EoMeta } from "/vercel/path0/pages/articles/category/[slug].vue?macro=true";
import { default as indexHSIYyOmJPsMeta } from "/vercel/path0/pages/articles/index.vue?macro=true";
import { default as _91slug_93uUvdvuhnXuMeta } from "/vercel/path0/pages/articles/tag/[slug].vue?macro=true";
import { default as articlesSToQQf8MXRMeta } from "/vercel/path0/pages/articles.vue?macro=true";
import { default as _91_46_46_46slug_932nbODr8xt9Meta } from "/vercel/path0/pages/family-doctor/[...slug].vue?macro=true";
import { default as indexKywtecbJYGMeta } from "/vercel/path0/pages/family-doctor/index.vue?macro=true";
import { default as family_45doctorC06S5cUS0fMeta } from "/vercel/path0/pages/family-doctor.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93B7jplwIzk9Meta } from "/vercel/path0/pages/outreach/[...slug].vue?macro=true";
import { default as indexJ9a6qa1OYJMeta } from "/vercel/path0/pages/outreach/index.vue?macro=true";
import { default as outreachQfPgbJbqcnMeta } from "/vercel/path0/pages/outreach.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as terms_45of_45useuG8Ysh8zj7Meta } from "/vercel/path0/pages/terms-of-use.vue?macro=true";
import { default as _91_46_46_46slug_93ABFuqwdYNHMeta } from "/vercel/path0/pages/tests/[...slug].vue?macro=true";
import { default as indexvZ4f0BlHrRMeta } from "/vercel/path0/pages/tests/index.vue?macro=true";
import { default as testshdwxbh1g1UMeta } from "/vercel/path0/pages/tests.vue?macro=true";
import { default as _91_46_46_46slug_93D2uGujfd8wMeta } from "/vercel/path0/pages/travel-health/[...slug].vue?macro=true";
import { default as indexXwCtw93VbsMeta } from "/vercel/path0/pages/travel-health/index.vue?macro=true";
import { default as travel_45health8wJsxRqRELMeta } from "/vercel/path0/pages/travel-health.vue?macro=true";
import { default as _91_46_46_46slug_93wIh4tLVKgxMeta } from "/vercel/path0/pages/vaccines/[...slug].vue?macro=true";
import { default as vaccines5FxW107GBkMeta } from "/vercel/path0/pages/vaccines.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___en",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "about___hk",
    path: "/hk/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "announcement___en",
    path: "/announcement",
    component: () => import("/vercel/path0/pages/announcement.vue")
  },
  {
    name: "announcement___hk",
    path: "/hk/announcement",
    component: () => import("/vercel/path0/pages/announcement.vue")
  },
  {
    name: "appointment___en",
    path: "/appointment",
    component: () => import("/vercel/path0/pages/appointment.vue")
  },
  {
    name: "appointment___hk",
    path: "/hk/appointment",
    component: () => import("/vercel/path0/pages/appointment.vue")
  },
  {
    name: articlesSToQQf8MXRMeta?.name,
    path: "/articles",
    component: () => import("/vercel/path0/pages/articles.vue"),
    children: [
  {
    name: "articles-slug___en",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/articles/[...slug].vue")
  },
  {
    name: "articles-category-slug___en",
    path: "category/:slug()",
    component: () => import("/vercel/path0/pages/articles/category/[slug].vue")
  },
  {
    name: "articles___en",
    path: "",
    component: () => import("/vercel/path0/pages/articles/index.vue")
  },
  {
    name: "articles-tag-slug___en",
    path: "tag/:slug()",
    component: () => import("/vercel/path0/pages/articles/tag/[slug].vue")
  }
]
  },
  {
    name: articlesSToQQf8MXRMeta?.name,
    path: "/hk/articles",
    component: () => import("/vercel/path0/pages/articles.vue"),
    children: [
  {
    name: "articles-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/articles/[...slug].vue")
  },
  {
    name: "articles-category-slug___hk",
    path: "category/:slug()",
    component: () => import("/vercel/path0/pages/articles/category/[slug].vue")
  },
  {
    name: "articles___hk",
    path: "",
    component: () => import("/vercel/path0/pages/articles/index.vue")
  },
  {
    name: "articles-tag-slug___hk",
    path: "tag/:slug()",
    component: () => import("/vercel/path0/pages/articles/tag/[slug].vue")
  }
]
  },
  {
    name: family_45doctorC06S5cUS0fMeta?.name,
    path: "/family-doctor",
    component: () => import("/vercel/path0/pages/family-doctor.vue"),
    children: [
  {
    name: "family-doctor-slug___en",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/family-doctor/[...slug].vue")
  },
  {
    name: "family-doctor___en",
    path: "",
    component: () => import("/vercel/path0/pages/family-doctor/index.vue")
  }
]
  },
  {
    name: family_45doctorC06S5cUS0fMeta?.name,
    path: "/hk/family-doctor",
    component: () => import("/vercel/path0/pages/family-doctor.vue"),
    children: [
  {
    name: "family-doctor-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/family-doctor/[...slug].vue")
  },
  {
    name: "family-doctor___hk",
    path: "",
    component: () => import("/vercel/path0/pages/family-doctor/index.vue")
  }
]
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___hk",
    path: "/hk",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: outreachQfPgbJbqcnMeta?.name,
    path: "/outreach",
    component: () => import("/vercel/path0/pages/outreach.vue"),
    children: [
  {
    name: "outreach-slug___en",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/outreach/[...slug].vue")
  },
  {
    name: "outreach___en",
    path: "",
    component: () => import("/vercel/path0/pages/outreach/index.vue")
  }
]
  },
  {
    name: outreachQfPgbJbqcnMeta?.name,
    path: "/hk/outreach",
    component: () => import("/vercel/path0/pages/outreach.vue"),
    children: [
  {
    name: "outreach-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/outreach/[...slug].vue")
  },
  {
    name: "outreach___hk",
    path: "",
    component: () => import("/vercel/path0/pages/outreach/index.vue")
  }
]
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___hk",
    path: "/hk/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy.vue")
  },
  {
    name: "terms-of-use___en",
    path: "/terms-of-use",
    component: () => import("/vercel/path0/pages/terms-of-use.vue")
  },
  {
    name: "terms-of-use___hk",
    path: "/hk/terms-of-use",
    component: () => import("/vercel/path0/pages/terms-of-use.vue")
  },
  {
    name: testshdwxbh1g1UMeta?.name,
    path: "/tests",
    component: () => import("/vercel/path0/pages/tests.vue"),
    children: [
  {
    name: "tests-slug___en",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/tests/[...slug].vue")
  },
  {
    name: "tests___en",
    path: "",
    component: () => import("/vercel/path0/pages/tests/index.vue")
  }
]
  },
  {
    name: testshdwxbh1g1UMeta?.name,
    path: "/hk/tests",
    component: () => import("/vercel/path0/pages/tests.vue"),
    children: [
  {
    name: "tests-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/tests/[...slug].vue")
  },
  {
    name: "tests___hk",
    path: "",
    component: () => import("/vercel/path0/pages/tests/index.vue")
  }
]
  },
  {
    name: travel_45health8wJsxRqRELMeta?.name,
    path: "/travel-health",
    component: () => import("/vercel/path0/pages/travel-health.vue"),
    children: [
  {
    name: "travel-health-slug___en",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/travel-health/[...slug].vue")
  },
  {
    name: "travel-health___en",
    path: "",
    component: () => import("/vercel/path0/pages/travel-health/index.vue")
  }
]
  },
  {
    name: travel_45health8wJsxRqRELMeta?.name,
    path: "/hk/travel-health",
    component: () => import("/vercel/path0/pages/travel-health.vue"),
    children: [
  {
    name: "travel-health-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/travel-health/[...slug].vue")
  },
  {
    name: "travel-health___hk",
    path: "",
    component: () => import("/vercel/path0/pages/travel-health/index.vue")
  }
]
  },
  {
    name: "vaccines___en",
    path: "/vaccines",
    component: () => import("/vercel/path0/pages/vaccines.vue"),
    children: [
  {
    name: "vaccines-slug___en",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/vaccines/[...slug].vue")
  }
]
  },
  {
    name: "vaccines___hk",
    path: "/hk/vaccines",
    component: () => import("/vercel/path0/pages/vaccines.vue"),
    children: [
  {
    name: "vaccines-slug___hk",
    path: ":slug(.*)*",
    component: () => import("/vercel/path0/pages/vaccines/[...slug].vue")
  }
]
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en/health-screening-packages/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en/subsidised-vaccine/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en/medical-consultaion/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en/outreach-for-groups/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/hk/health-screening-packages/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/hk/subsidised-vaccine/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/hk/medical-consultaion/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/hk/outreach-for-groups/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  }
]